import { applyMiddleware, createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist"

import { apiMiddleware } from "redux-api-middleware"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"
import createSagaMiddleware from "redux-saga"
import requestMiddleware from "./middleware/requestMiddleware"
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "whatsmine@v2",
  storage,
  whitelist: ["auth", "account", "referrals"],
}

const sagaMiddleware = createSagaMiddleware()
const middlewares = [requestMiddleware, apiMiddleware, sagaMiddleware]
const persistedReducer = persistReducer(persistConfig, rootReducer)
const enhancer = composeWithDevTools({ maxAge: 200 })(
  applyMiddleware(...middlewares)
)

export default () => {
  let store = createStore(persistedReducer, enhancer)
  let persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer))
  }

  return { store, persistor }
}
