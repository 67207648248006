import * as actionTypes from "../actions/action-types"

export const initialState = {
  offers: [],
  articles: [],
}

const addLike = (state, payload) => [...state, payload]
const removeLike = (state, payload) => state.filter(item => item !== payload)

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OFFER_LIKE:
      return {
        ...state,
        offers: addLike(state.offers, action.payload),
      }
    case actionTypes.OFFER_UNLIKE:
      return {
        ...state,
        offers: removeLike(state.offers, action.payload),
      }
    case actionTypes.ARTICLE_LIKE:
      return {
        ...state,
        articles: addLike(state.articles, action.payload),
      }
    case actionTypes.ARTICLE_UNLIKE:
      return {
        ...state,
        articles: removeLike(state.articles, action.payload),
      }
    default:
      return state
  }
}

export default reducer
