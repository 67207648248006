import actionTypes from "../../actions/actionTypes"
import { createAction } from "redux-api-middleware"

export default payload =>
  createAction({
    endpoint: `/wallet-items/${payload}`,
    method: "DELETE",
    types: [
      actionTypes.REQUEST_REMOVE_WALLET_ITEM_PERFORM,
      actionTypes.REQUEST_REMOVE_WALLET_ITEM_SUCCESS,
      actionTypes.REQUEST_REMOVE_WALLET_ITEM_FAILURE,
    ],
  })
