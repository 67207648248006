import * as articleActionCreators from "../../articles/store/actions/actionCreators"
import * as authActionCreators from "../../auth/store/actions/actionCreators"
import * as brandActionCreators from "../../brands/store/actions/actionCreators"
import * as cafeActionCreators from "../../cafes/store/actions/actionCreators"
import * as contextSelectors from "../../auth/store/selectors/context-selector"
import * as offerActionCreators from "../../offers/store/actions/actionCreators"

import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { BuildContext } from "../utils"
import commonActionCreators from "../store/actions/action-creators"
import { connect } from "react-redux"
import get from "lodash/get"
import { useArticlesLoader } from "./ArticlesDataLoader"
import { useBrandsLoader } from "./BrandsDataLoader"
import { useOffersLoader } from "./OffersDataLoader"
import uuidv4 from "uuid/v4"

const SourceDataLoader = ({
  addDeviceID,
  addEntities,
  addEnvContext,
  addPageInfo,
  children,
  deviceID,
  loadArticlesSuccess,
  loadBrandsSuccess,
  loadOffersSuccess,
  loadCafesSuccess,
  requestLoadArticlesPerform,
  requestLoadBrandsPerform,
  requestLoadOffersPerform,
  requestLoadCafesPerform,
  requestLoadArticlesSuccess,
  requestLoadBrandsSuccess,
  requestLoadOffersSuccess,
  requestLoadCafesSuccess,
}) => {
  const site = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            branch
            ctx
            isProduction
          }
        }
      }
    `
  )

  useEffect(() => {
    if (!deviceID) {
      const id = uuidv4()
      addDeviceID(id)
    }
  }, [])

  // load the data into redux
  useArticlesLoader(
    loadArticlesSuccess,
    addEntities,
    addPageInfo,
    requestLoadArticlesPerform,
    requestLoadArticlesSuccess
  )
  useOffersLoader(
    loadOffersSuccess,
    addEntities,
    addPageInfo,
    requestLoadOffersPerform,
    requestLoadOffersSuccess
  )
  useBrandsLoader(
    loadBrandsSuccess,
    loadCafesSuccess,
    addEntities,
    addPageInfo,
    requestLoadBrandsPerform,
    requestLoadBrandsSuccess,
    requestLoadCafesPerform,
    requestLoadCafesSuccess
  )

  const { branch, ctx, isProduction } = get(site, "site.siteMetadata", {})
  const buildContextValue = { branch, ctx, isProduction }

  return (
    <BuildContext.Provider value={buildContextValue}>
      {children}
    </BuildContext.Provider>
  )
}

const mapStateToProps = state => ({
  deviceID: contextSelectors.deviceID$(state),
})

const mapDispatchToProps = {
  addDeviceID: authActionCreators.addDeviceID,
  addEntities: commonActionCreators.addEntities,
  addEnvContext: authActionCreators.addEnvContext,
  addPageInfo: commonActionCreators.addPageInfo,
  requestLoadArticlesPerform: articleActionCreators.requestLoadItemsPerform,
  requestLoadBrandsPerform: brandActionCreators.requestLoadItemsPerform,
  requestLoadOffersPerform: offerActionCreators.requestLoadItemsPerform,
  requestLoadCafesPerform: cafeActionCreators.requestLoadItemsPerform,
  requestLoadBrandsSuccess: brandActionCreators.requestLoadItemsSuccess,
  requestLoadArticlesSuccess: articleActionCreators.requestLoadItemsSuccess,
  requestLoadOffersSuccess: offerActionCreators.requestLoadItemsSuccess,
  requestLoadCafesSuccess: cafeActionCreators.requestLoadItemsSuccess,
  loadArticlesSuccess: articleActionCreators.loadArticlesSuccess,
  loadBrandsSuccess: brandActionCreators.loadBrandsSuccess,
  loadOffersSuccess: offerActionCreators.loadOffersSuccess,
  loadCafesSuccess: cafeActionCreators.loadCafesSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(SourceDataLoader)
