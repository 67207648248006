import * as actionTypes from "../actions/action-types"
import { ENTITY_TYPES, entityDefaultFilter } from "../../../common/utils"

const initialStateCreator = () => {
  const state = {
    entityType: "",
  }
  Object.values(ENTITY_TYPES).forEach(entity => {
    state[entity] = {
      filter: entityDefaultFilter(entity),
      search: "",
    }
  })
  return state
}

export const initialState = initialStateCreator()

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ADD_FILTER:
      return {
        ...state,
        [state.entityType]: { ...state[state.entityType], filter: payload },
      }
    case actionTypes.ADD_SEARCH:
      return {
        ...state,
        [state.entityType]: { ...state[state.entityType], search: payload },
      }
    case actionTypes.ADD_SEARCH_ENTITY:
      return {
        ...state,
        entityType: payload,
      }
    default:
      return state
  }
}

export default reducer
