export const NETWORK_ERROR = "ERROR__ADD_NETWORK"
export const ADD_ERROR = "ERROR__ADD"
export const START_LOADING = "LOADING__START"
export const STOP_LOADING = "LOADING__STOP"
export const START_SUBMITTING = "SUBMITTING__START"
export const STOP_SUBMITTING = "SUBMITTING__STOP"
export const ADD_ENTITIES = "ENTITIES__ADD"
export const ADD_ENTITY = "ENTITY__ADD"
export const CLEAR_ENTITIES = "ENTITIES__CLEAR"
export const ADD_PAGE_INFO = "PAGE_INFO__ADD"
export const REQUEST_ERROR = "ERROR__REQUEST"
export const REQUEST_SUCCESS = "SUCCESS__REQUEST"
