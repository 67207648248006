import { createAction, getJSON } from "redux-api-middleware"

import actionTypes from "../../actions/actionTypes"
import { camelize } from "../../../../common/utils"

const mapSuccessPayload = (action, state, res) =>
  getJSON(res).then(json => camelize(json))

export default () =>
  createAction({
    endpoint: `/users/`,
    method: "GET",
    types: [
      actionTypes.REQUEST_LOAD_USER_PERFORM,
      {
        type: actionTypes.REQUEST_LOAD_USER_SUCCESS,
        payload: mapSuccessPayload,
      },
      actionTypes.REQUEST_LOAD_USER_FAILURE,
    ],
  })
