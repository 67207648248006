export default {
  ABOUT: "/about",
  ACCOUNT: "/app/account",
  ARTICLES: "/articles",
  BRANDS: "/brands",
  CAFES_ABOUT: "/cafes/about",
  CAFES_REFERRAL: "/cafes/new",
  CAFES: "/cafes",
  CALLBACK: "/app/callback",
  COMPETITIONS: "/competitions",
  HOME: "/",
  OFFERS: "/offers",
  SIGN_IN_CODE: "/app/signin/code",
  SIGN_IN: "/app/signin",
  SIGN_OUT: "/app/signout",
  WALLET: "/app/wallet",
}
