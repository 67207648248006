import { ENTITY_ITEMS } from "./"

export const actionCreator = type => {
  return payload => ({
    type,
    payload,
  })
}

const newItems = (state, items) =>
  items.filter(item => !!item && !state.includes(item))

const createInitialItemState = entityType => {
  const items = ENTITY_ITEMS[entityType]
  return Object.keys(items.filters).reduce((n, k) => {
    n[k] = []
    return n
  }, {})
}

export function createItemsReducer(actionTypes, entityType) {
  return (state = createInitialItemState(entityType), { type, payload }) => {
    switch (type) {
      case actionTypes.LOAD_ITEMS_SUCCESS:
        return {
          ...state,
          [payload.filter]: [
            ...state[payload.filter],
            ...newItems(state[payload.filter], payload.ids),
          ],
        }

      default:
        return state
    }
  }
}
