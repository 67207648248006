import {
  call,
  cancelled,
  put,
  putResolve,
  takeLatest,
} from "redux-saga/effects"
import {
  createUserCancelled,
  createUserFailure,
  createUserSuccess,
} from "../actions/actionCreators"

import actionTypes from "../actions/actionTypes"
import createUserAction from "./api/createUserAction"

function* createUser(payload) {
  try {
    return yield putResolve(createUserAction(payload))
  } catch (error) {
    return null
  } finally {
    if (yield cancelled()) {
      yield put(createUserCancelled())
      // ... put special cancellation handling code here
    }
  }
}

function* instanceSaga(action) {
  const results = yield call(createUser, action.payload)

  if (!results || results.error) {
    // yield put(signOutStarted())
    yield put(createUserFailure())
  } else {
    yield put(createUserSuccess(action.payload))
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* createUserSaga() {
  yield takeLatest(actionTypes.CREATE_USER_STARTED, instanceSaga)
}
