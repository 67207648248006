export default {
  ADD_PAGE_INFO: "ARTICLES__ADD_PAGE_INFO",
  LOAD_ITEMS_STARTED: "ARTICLES__LOAD_ARTICLES_STARTED",
  LOAD_ITEMS_SUCCESS: "ARTICLES__LOAD_ARTICLES_SUCCESS",
  LOAD_ITEMS_FAILURE: "ARTICLES__LOAD_ARTICLES_FAILURE",
  LOAD_ITEMS_CANCELLED: "ARTICLES__LOAD_ARTICLES_CANCELLED",

  REQUEST_LOAD_ITEMS_PERFORM: "REQUEST_LOAD_ARTICLES_PERFORM",
  REQUEST_LOAD_ITEMS_SUCCESS: "REQUEST_LOAD_ARTICLES_SUCCESS",
  REQUEST_LOAD_ITEMS_FAILURE: "REQUEST_LOAD_ARTICLES_FAILURE",
}
