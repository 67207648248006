import actionTypes from "../actions/actionTypes"

const initialState = {
  deviceID: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ADD_DEVICE_ID:
      return { ...state, deviceID: payload }

    default:
      return state
  }
}
