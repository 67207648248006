import "react-toastify/dist/ReactToastify.css"

import { Slide, ToastContainer } from "react-toastify"

import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import React from "react"
import SourceDataLoader from "./SourceDataLoader"
import configureStore from "../store/configureStore"

const { store, persistor } = configureStore()

export default ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer
        hideProgressBar
        transition={Slide}
        className="w-full md:w-1/3 top-0 right-0"
      />
      <SourceDataLoader>{element}</SourceDataLoader>
    </PersistGate>
  </Provider>
)
