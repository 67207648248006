import {
  call,
  cancelled,
  put,
  putResolve,
  takeLatest,
} from "redux-saga/effects"
import {
  loadWalletItemsStarted,
  removeWalletItemCancelled,
  removeWalletItemFailure,
  removeWalletItemSuccess,
} from "../actions/actionCreators"
import { toastError, toastSuccess } from "../../../common/utils"

import { BRAND_FILTER_TYPES } from "../../../common/utils"
import { DEFAULT_PAGE_SIZE } from "../../../common/utils/index"
import actionTypes from "../actions/actionTypes"
import removeWalletItemAction from "./api/removeWalletItemAction"

function* removeWalletItem(payload) {
  try {
    return yield putResolve(removeWalletItemAction(payload))
  } catch (error) {
    return null
  } finally {
    if (yield cancelled()) {
      yield put(removeWalletItemCancelled())
      // ... put special cancellation handling code here
    }
  }
}

function* instanceSaga(action) {
  const results = yield call(removeWalletItem, action.payload)

  if (!results || results.error) {
    yield put(removeWalletItemFailure())
    toastError("Failed to remove brand from wallet!")
  } else {
    yield put(loadWalletItemsStarted())
    yield put(removeWalletItemSuccess(action.payload))
    toastSuccess("Successfully removed brand from wallet!")
  }
}

export function* removeWalletItemSaga() {
  yield takeLatest(actionTypes.REMOVE_WALLET_ITEM_STARTED, instanceSaga)
}
