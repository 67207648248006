import actionTypes from "../actions/actionTypes"

const initialState = {
  redirectTo: "/",
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ADD_REDIRECT_TO:
      return {
        ...state,
        redirectTo: payload || null,
      }
    default:
      return state
  }
}
