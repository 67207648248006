import actionTypes from "../actions/actionTypes"

const initialState = {
  firstName: null,
  lastName: null,
  email: null,
  imageUrl: null,
  dateOfBirth: null,
  gender: null,
  postcode: null,
  subscribed: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.REQUEST_SIGN_IN_SUCCESS:
    case actionTypes.LOAD_USER_SUCCESS:
    case actionTypes.UPDATE_USER_SUCCESS:
    case actionTypes.CREATE_USER_SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
