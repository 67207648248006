import { BRAND_FILTER_TYPES, CAFE_FILTER_TYPES, ENTITY_TYPES } from "../utils"
import { graphql, useStaticQuery } from "gatsby"
import {
  mapEdgesToNodes,
  mapEntityToUUID,
} from "../store/selectors/source-data-selector"

import { useEffect } from "react"

const mapEntities = data => {
  const cafes = data.filter(brand => !!brand.is_stamp)
  const brands = data.filter(brand => !brand.is_stamp)
  return {
    cafes,
    brands,
  }
}

export const useBrandsLoader = (
  loadBrandsSuccess,
  loadCafesSuccess,
  addEntities,
  addPageInfo,
  requestLoadBrandsPerform,
  requestLoadBrandsSuccess,
  requestLoadCafesPerform,
  requestLoadCafesSuccess
) => {
  const { allInternalBrands } = useStaticQuery(
    graphql`
      {
        allInternalBrands(sort: { fields: name, order: ASC }) {
          edges {
            node {
              id
              uuid
              name
              is_stamp
              keywords
              most_popular
              card_asset_image {
                medium
              }
              description
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    requestLoadBrandsPerform()
    requestLoadCafesPerform()
    const entities = mapEdgesToNodes(allInternalBrands)
    const { brands, cafes } = mapEntities(entities)
    const brandIds = mapEntityToUUID(brands)
    const cafeIds = mapEntityToUUID(cafes)

    // add brands
    addEntities(ENTITY_TYPES.BRANDS, brands)
    loadBrandsSuccess({
      ids: brandIds,
      filter: BRAND_FILTER_TYPES.ALL,
    })

    addPageInfo({
      entityType: ENTITY_TYPES.BRANDS,
      filterType: BRAND_FILTER_TYPES.ALL,
      pageInfo: {
        count: brandIds.length,
        offset: 0,
      },
    })

    // add cafes
    addEntities(ENTITY_TYPES.CAFES, cafes)
    loadCafesSuccess({ ids: cafeIds, filter: CAFE_FILTER_TYPES.ALL })

    addPageInfo({
      entityType: ENTITY_TYPES.CAFES,
      filterType: CAFE_FILTER_TYPES.ALL,
      pageInfo: {
        count: cafeIds.length,
        offset: 0,
      },
    })
    requestLoadBrandsSuccess()
    requestLoadCafesSuccess()
  }, [])
}
