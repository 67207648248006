import {
  call,
  cancelled,
  put,
  putResolve,
  takeLatest,
} from "redux-saga/effects"
import {
  createWalletItemCancelled,
  createWalletItemFailure,
  createWalletItemSuccess,
} from "../actions/actionCreators"
import { toastError, toastSuccess } from "../../../common/utils"

import actionTypes from "../actions/actionTypes"
import createWalletItemAction from "./api/createWalletItemAction"

function* createWalletItem(payload) {
  try {
    return yield putResolve(createWalletItemAction(payload))
  } catch (error) {
    return null
  } finally {
    if (yield cancelled()) {
      yield put(createWalletItemCancelled())
      // ... put special cancellation handling code here
    }
  }
}

function* instanceSaga(action) {
  const walletItem = { program: action.payload }
  const results = yield call(createWalletItem, walletItem)

  if (!results || results.error) {
    yield put(createWalletItemFailure())
    toastError("Failed to add brand to wallet!")
  } else {
    yield put(createWalletItemSuccess(action.payload))
    toastSuccess("Successfully added brand to wallet!")
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* createWalletItemSaga() {
  yield takeLatest(actionTypes.CREATE_WALLET_ITEM_STARTED, instanceSaga)
}
