import * as actionTypes from "../actions/action-types"

import { ENTITY_ITEMS } from "../../utils"

const initialStateCreator = () => {
  const state = {}
  Object.keys(ENTITY_ITEMS).forEach(k1 => {
    const filters = Object.keys(ENTITY_ITEMS[k1]["filters"])
    const filterPages = filters.reduce((pages, k2) => {
      pages[k2] = {}
      return pages
    }, {})
    state[k1] = filterPages
  })

  return state
}

export const initialState = initialStateCreator()

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ADD_PAGE_INFO: {
      const { entityType, filterType, pageInfo } = payload
      const entity = state[entityType]
      const entityPageInfo = entity[filterType]

      return {
        ...state,
        [entityType]: {
          ...entity,
          [filterType]: { ...entityPageInfo, ...pageInfo },
        },
      }
    }

    default:
      return state
  }
}
