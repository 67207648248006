import { createAction, getJSON } from "redux-api-middleware"
import {
  requestCreateUserFailure,
  requestCreateUserPerform,
  requestCreateUserSuccess,
} from "../../actions/actionCreators"

import { camelize } from "../../../../common/utils"

const mapSuccessPayload = (action, state, res) =>
  getJSON(res).then(json => camelize(json))

export default payload =>
  createAction({
    endpoint: `/users/`,
    method: "POST",
    body: JSON.stringify(payload),
    types: [
      requestCreateUserPerform(),
      requestCreateUserSuccess(mapSuccessPayload),
      requestCreateUserFailure(),
    ],
  })
