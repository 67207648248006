import { createSelector } from "reselect"
import get from "lodash/get"

const uriToAllInternalSourceQuery = ({ uri }) => {
  switch (uri) {
    case "/brands":
      return "data.allInternalBrands"
    case "/offers":
      return "data.allInternalOffers"
    case "/articles":
      return "data.allContentfulArticle"
    default:
      return null
  }
}

export const allInternal$ = (_, props) =>
  get(props, uriToAllInternalSourceQuery(props), {})

export const mapEdgesToNodes = state =>
  get(state, "edges", []).map(edge => edge.node)

export const mapContentfulEdgesToNodes = state =>
  get(state, "edges", []).map(edge => ({ ...edge.node, uuid: edge.node.id }))

export const mapEntityToUUID = state => state.map(entity => entity.uuid)

export const allInternalEntities$ = createSelector(
  [allInternal$],
  mapEdgesToNodes
)

export const allInternalIds$ = createSelector(
  [allInternalEntities$],
  mapEntityToUUID
)
