import { call, cancelled, delay, put, takeLatest } from "redux-saga/effects"
import {
  requestSignOutFailure,
  requestSignOutPerform,
  requestSignOutSuccess,
  signOutCancelled,
  signOutFailure,
  signOutSuccess,
} from "../actions/actionCreators"

import actionTypes from "../actions/actionTypes"
import { signOut } from "../../api"

export function* instanceSaga(action) {
  try {
    yield put(requestSignOutPerform())
    yield delay(2000)
    yield call(signOut)
    yield put(requestSignOutSuccess())
    yield put(signOutSuccess())
  } catch (error) {
    yield put(requestSignOutFailure())
    yield put(signOutFailure(error))
  } finally {
    if (yield cancelled()) {
      yield put(signOutCancelled())
    }
  }
}

export function* signOutSaga() {
  yield takeLatest(actionTypes.SIGN_OUT_STARTED, instanceSaga)
}
