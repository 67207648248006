import { Auth0Factory } from "../utils"
import { getAuthResult } from "../utils"

export const signIn = email =>
  new Promise((resolve, reject) => {
    Auth0Factory.getInstance().passwordlessStart(
      {
        email,
        connection: "email",
        send: "code",
      },
      (error, authResult) => {
        if (error) {
          return reject(error)
        }

        resolve({
          email,
        })
      }
    )
  })

export const signInConfirmation = data =>
  new Promise((resolve, reject) => {
    Auth0Factory.getInstance().passwordlessLogin(
      {
        email: data.email,
        verificationCode: data.code,
        connection: "email",
      },
      (error, authResult) => {
        if (error) {
          reject(error)
          return
        }
        resolve({ authResult })
      }
    )
  })

export const handleAuthentication = () =>
  new Promise((resolve, reject) => {
    Auth0Factory.getInstance().parseHash(
      { hash: window.location.hash },
      (error, authResult) => {
        if (error) {
          reject(error)
          return
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          const { user, tokens } = getAuthResult(authResult)
          resolve({ user, tokens })
        } else {
          reject(error)
        }
      }
    )
  })

export const checkSession = () =>
  new Promise((resolve, reject) => {
    Auth0Factory.getInstance().checkSession({}, (error, authResult) => {
      if (error) {
        reject(error)
        return
      }
      if (authResult && authResult.accessToken && authResult.idToken) {
        const { tokens } = getAuthResult(authResult)
        resolve({ tokens })
      } else {
        reject(error)
      }
    })
  })

export const signOut = () =>
  new Promise((resolve, reject) => {
    Auth0Factory.getInstance().logout({
      returnTo: process.env.GATSBY_AUTH0_LOGOUT_RETURN_TO,
      client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
    })
    resolve({ payload: true })
  })
