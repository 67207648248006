import { authenticated } from "../../utils"
import { createSelector } from "reselect"

const tokenState = state => state.auth.tokens

export const accessToken$ = createSelector(
  [tokenState],
  state => state.accessToken
)

export const expiresAt$ = createSelector([tokenState], state => state.expiresAt)

export const isSignedIn$ = createSelector(
  [accessToken$],
  state => authenticated(state) || state
)
