import { all } from "redux-saga/effects"
import authSaga from "../../../auth/store/sagas"
import cafeSagas from "../../../cafes/store/sagas"
import offerSagas from "../../../offers/store/sagas"
import userSagas from "../../../accounts/store/sagas"
import walletItemSagas from "../../../walletItems/store/sagas"

function* rootSaga() {
  yield all([
    ...authSaga,
    ...cafeSagas,
    ...offerSagas,
    ...userSagas,
    ...walletItemSagas,
  ])
}

export default rootSaga
