// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-articles-templates-articles-js": () => import("./../src/articles/templates/Articles.js" /* webpackChunkName: "component---src-articles-templates-articles-js" */),
  "component---src-brands-templates-brands-js": () => import("./../src/brands/templates/Brands.js" /* webpackChunkName: "component---src-brands-templates-brands-js" */),
  "component---src-articles-templates-article-js": () => import("./../src/articles/templates/Article.js" /* webpackChunkName: "component---src-articles-templates-article-js" */),
  "component---src-brands-templates-brand-js": () => import("./../src/brands/templates/Brand.js" /* webpackChunkName: "component---src-brands-templates-brand-js" */),
  "component---src-legal-templates-legal-js": () => import("./../src/legal/templates/Legal.js" /* webpackChunkName: "component---src-legal-templates-legal-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("./../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-competitions-js": () => import("./../src/pages/competitions.js" /* webpackChunkName: "component---src-pages-competitions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

