import * as tokenSelectors from "../selectors/token-selector"

import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from "redux-saga/effects"
import {
  checkSessionCancelled,
  checkSessionFailure,
  checkSessionSuccess,
  requestCheckSessionFailure,
  requestCheckSessionPerform,
  requestCheckSessionSuccess,
} from "../actions/actionCreators"

import actionTypes from "../actions/actionTypes"
import { checkSession } from "../../api"
import { loadWalletItemsStarted } from "../../../walletItems/store/actions/actionCreators"
import { signOutSaga } from "./signOutSaga"

function* instanceSaga(action) {
  try {
    yield put(requestCheckSessionPerform())
    const isSignedIn = yield select(tokenSelectors.isSignedIn$)
    if (isSignedIn) {
      yield put(requestCheckSessionSuccess())
      yield put(checkSessionSuccess())
    } else {
      // refresh JWT
      const checkSessionResult = yield call(checkSession)
      yield put(requestCheckSessionSuccess())
      yield put(checkSessionSuccess(checkSessionResult.tokens))
    }
    yield put(loadWalletItemsStarted())
  } catch (error) {
    yield put(requestCheckSessionFailure())
    yield put(checkSessionFailure(error))
  } finally {
    if (yield cancelled()) {
      yield put(checkSessionCancelled())
    }
  }
}

export function* checkSessionSaga() {
  while (true) {
    const action = yield take(actionTypes.CHECK_SESSION_STARTED)
    const task = yield fork(instanceSaga, action)
    yield take([
      actionTypes.CHECK_SESSION_FAILURE,
      actionTypes.SIGN_OUT_STARTED,
      actionTypes.CHECK_SESSION_STARTED,
    ])

    if (action.type === actionTypes.SIGN_OUT_STARTED) {
      yield cancel(task)
    }

    if (action.type === actionTypes.CHECK_SESSION_FAILURE) {
      yield put(signOutSaga())
    }

    if (action.type === actionTypes.CHECK_SESSION_STARTED) {
      yield call(instanceSaga)
    }
  }
}
