import { call, cancelled, put, takeEvery } from "redux-saga/effects"
import {
  requestSignInConfirmationFailure,
  requestSignInConfirmationPerform,
  requestSignInConfirmationSuccess,
  signInConfirmationCancelled,
  signInConfirmationFailure,
  signInConfirmationSuccess,
} from "../actions/actionCreators"

import actionTypes from "../actions/actionTypes"
import appRoutes from "../../../common/utils/appRoutes"
import { navigate } from "@reach/router"
import { signInConfirmation } from "../../api"
import { toastError } from "../../../common/utils/index"
import get from "lodash/get"

function* instanceSaga(action) {
  try {
    yield put(requestSignInConfirmationPerform())
    const signInConfirmationResult = yield call(
      signInConfirmation,
      action.payload
    )
    yield put(requestSignInConfirmationSuccess())
    yield put(signInConfirmationSuccess(signInConfirmationResult))
  } catch (error) {
    toastError(get(error, "description", "Failed confirm sign in!"))
    yield put(requestSignInConfirmationFailure())
    yield put(signInConfirmationFailure(error))
  } finally {
    if (yield cancelled()) {
      yield put(signInConfirmationCancelled())
      navigate(appRoutes.SIGN_IN)
    }
  }
}

export function* signInConfirmationSaga() {
  yield takeEvery(actionTypes.SIGN_IN_CONFIRMATION_STARTED, instanceSaga)
}
