import * as actionTypes from "../actions/action-types"

import { ENTITY_TYPES, arrayToObject } from "../../utils"

import get from "lodash/get"
import isNil from "lodash/isNil"

function createInitialEntitiesState() {
  return Object.values(ENTITY_TYPES).reduce((n, k) => {
    n[k] = {}
    return n
  }, {})
}

const initialState = createInitialEntitiesState()

const entitiesByTypeState = (state, payload) => {
  const entities = get(payload, "entities")
  const entityType = get(payload, "entityType")

  if (!isNil(entities) && !isNil(entityType)) {
    const entitiesByEntityType = state[entityType]
    const entitiesObject = arrayToObject(entities)
    const newEntitiesForEntity = { ...entitiesByEntityType, ...entitiesObject }
    return { [entityType]: newEntitiesForEntity }
  }
}

const entityByTypeState = (state, payload) => {
  const entity = get(payload, "entity")
  const entityType = get(payload, "entityType")

  if (!isNil(entity) && !isNil(entityType)) {
    const entitiesByEntityType = state[entityType]
    const newEntitiesForEntity = {
      ...entitiesByEntityType,
      [entity.uuid || entity.id]: entity,
    }
    return { [entityType]: newEntitiesForEntity }
  }
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ADD_ENTITY: {
      return {
        ...state,
        ...entityByTypeState(state, payload),
      }
    }
    case actionTypes.ADD_ENTITIES: {
      return {
        ...state,
        ...entitiesByTypeState(state, payload),
      }
    }
    case actionTypes.CLEAR_ENTITIES: {
      const entityType = get(payload, "entityType")
      return {
        ...state,
        [entityType]: {},
      }
    }
    default:
      return state
  }
}
