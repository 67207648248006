module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-140820967-3"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"cookie_name":"whatmineapp"},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WhatsMine App","short_name":"WhatsMine","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/whatsmine-logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"fgtbtlhv","include_in_development":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"567328413681100"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"enableOnDevMode":true,"apiToken":"6ae99ab034ca7a7fc5af7dc8e0b688ed","pageViews":{"/":"Home - Viewed","/about":"About - Viewed","/articles":"Articles - Viewed","/explore/brands":"Brands - Viewed","/explore/offers":"Offers - Viewed","/brands":"Brands - Viewed","/offers":"Offers - Viewed","/404":"404 - Viewed"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"356252321624904","xfbml":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
