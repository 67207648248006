import { ENTITY_TYPES, OFFER_FILTER_TYPES } from "../utils"
import { graphql, useStaticQuery } from "gatsby"
import {
  mapEdgesToNodes,
  mapEntityToUUID,
} from "../store/selectors/source-data-selector"

import { useEffect } from "react"

export const useOffersLoader = (
  loadItemsSuccess,
  addEntities,
  addPageInfo,
  requestLoadOffersPerform,
  requestLoadOffersSuccess
) => {
  const { allInternalOffers } = useStaticQuery(
    graphql`
      {
        allInternalOffers(
          limit: 2000
          sort: { fields: publish_date, order: DESC }
        ) {
          edges {
            node {
              uuid
              state
              short_title
              long_title
              description
              publish_date(formatString: "MMM DD, YYYY")
              categories {
                display_text
              }
              is_benefit
              program
              program_name
              hero_image {
                medium
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    requestLoadOffersPerform()
    const entities = mapEdgesToNodes(allInternalOffers)
    const ids = mapEntityToUUID(entities)

    addEntities(ENTITY_TYPES.OFFERS, entities)
    addPageInfo({
      entityType: ENTITY_TYPES.OFFERS,
      filterType: OFFER_FILTER_TYPES.ALL,
      pageInfo: {
        count: ids.length,
        offset: 0,
      },
    })
    loadItemsSuccess({
      ids,
      filter: OFFER_FILTER_TYPES.ALL,
    })
    requestLoadOffersSuccess()
  }, [])
}
