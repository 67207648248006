import { ENTITY_TYPES, WALLET_ITEM_FILTER_TYPES } from "../../../common/utils"
import {
  addWalletItemsPageInfo,
  loadWalletItemsCancelled,
  loadWalletItemsFailure,
  loadWalletItemsSuccess,
} from "../actions/actionCreators"
import { call, cancelled, put, putResolve, takeEvery } from "redux-saga/effects"

import actionTypes from "../actions/actionTypes"
import commonActionCreators from "../../../common/store/actions/action-creators"
import loadWalletItemsAction from "./api/loadWalletItemsAction"

export function* loadWalletItems(payload) {
  try {
    return yield putResolve(loadWalletItemsAction())
  } catch (error) {
    return null
  } finally {
    if (yield cancelled()) {
      yield put(loadWalletItemsCancelled())
      // ... put special cancellation handling code here
    }
  }
}

function* instanceSaga(action) {
  const results = yield call(loadWalletItems, action.payload)
  if (!results || results.error) {
    yield put(loadWalletItemsFailure())
  } else {
    yield put(
      loadWalletItemsSuccess({
        filter: WALLET_ITEM_FILTER_TYPES.ALL,
        ids: results.payload.ids,
      })
    )

    yield put(
      commonActionCreators.addEntities(
        ENTITY_TYPES.WALLET_ITEMS,
        results.payload.entities
      )
    )

    yield put(
      addWalletItemsPageInfo(WALLET_ITEM_FILTER_TYPES.ALL, {
        count: results.payload.ids.length,
        offset: 0,
      })
    )
  }
}

export function* loadWalletItemsSaga() {
  yield takeEvery(actionTypes.LOAD_ITEMS_STARTED, instanceSaga)
}
