import {
  call,
  cancelled,
  put,
  putResolve,
  takeLatest,
} from "redux-saga/effects"
import { toastError, toastSuccess } from "../../../common/utils"
import {
  updateUserCancelled,
  updateUserFailure,
  updateUserSuccess,
} from "../actions/actionCreators"

import actionTypes from "../actions/actionTypes"
import updateUserAction from "./api/updateUserAction"

export function* updateUser(payload) {
  try {
    return yield putResolve(updateUserAction(payload))
  } catch (error) {
    return null
  } finally {
    if (yield cancelled()) {
      yield put(updateUserCancelled())
      // ... put special cancellation handling code here
    }
  }
}

export function* instanceSaga(action) {
  const results = yield call(updateUser, action.payload)

  if (!results || results.error) {
    toastError("Failed to update user!")
    yield put(updateUserFailure(results.error))
  } else {
    toastSuccess("Update successful!")
    yield put(updateUserSuccess(results.payload))
  }
}

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC
export function* updateUserSaga() {
  yield takeLatest(actionTypes.UPDATE_USER_STARTED, instanceSaga)
}
