import { call, cancel, cancelled, fork, put, take } from "redux-saga/effects"
import {
  requestSignInFailure,
  requestSignInPerform,
  requestSignInSuccess,
  signInCancelled,
  signInFailure,
  signInSuccess,
} from "../actions/actionCreators"

import actionTypes from "../actions/actionTypes"
import appRoutes from "../../../common/utils/appRoutes"
import { navigate } from "@reach/router"
import { signIn } from "../../api"
import { signOutSaga } from "./signOutSaga"

function* authorize(payload) {
  try {
    yield put(requestSignInPerform())
    const signInResult = yield call(signIn, payload)
    yield put(requestSignInSuccess())
    yield put(signInSuccess(signInResult))
    navigate(appRoutes.SIGN_IN_CODE, {
      state: { email: signInResult.email },
    })
  } catch (error) {
    yield put(requestSignInFailure())
    yield put(signInFailure(error))
    return null
  } finally {
    if (yield cancelled()) {
      yield put(signInCancelled())
    }
  }
}

export function* signInSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.SIGN_IN_STARTED)
    const task = yield fork(authorize, payload)
    const action = yield take([
      actionTypes.SIGN_OUT_STARTED,
      actionTypes.SIGN_IN_FAILURE,
    ])
    if (action.type === actionTypes.SIGN_OUT_STARTED) yield cancel(task)
    yield call(signOutSaga)
  }
}
