import { createAction, getJSON } from "redux-api-middleware"

import actionTypes from "../../actions/actionTypes"
import { mapEntityToUUID } from "../../../../common/store/selectors/source-data-selector"

const mapSuccessPayload = (action, state, res) =>
  getJSON(res).then(json => {
    return {
      entities: json.map(walletItem => {
        return walletItem.program
          ? { ...walletItem.program, walletItemId: walletItem.uuid }
          : { ...walletItem.membership.program, walletItemId: walletItem.uuid }
      }),
      ids: mapEntityToUUID(json),
    }
  })

export default () =>
  createAction({
    endpoint: `/wallet-items/`,
    method: "GET",
    types: [
      actionTypes.REQUEST_LOAD_ITEMS_PERFORM,
      {
        type: actionTypes.REQUEST_LOAD_ITEMS_SUCCESS,
        payload: mapSuccessPayload,
      },
      actionTypes.REQUEST_LOAD_ITEMS_FAILURE,
    ],
  })
