import { ENTITY_TYPES } from "../../../common/utils"
import { actionCreator } from "../../../common/utils/redux-helper"
import actionTypes from "./actionTypes"
import commonActionCreators from "../../../common/store/actions/action-creators"

export const createCafeReferralStarted = actionCreator(
  actionTypes.CREATE_CAFE_REFERRAL_STARTED
)
export const createCafeReferralSuccess = actionCreator(
  actionTypes.CREATE_CAFE_REFERRAL_SUCCESS
)
export const createCafeReferralFailure = actionCreator(
  actionTypes.CREATE_CAFE_REFERRAL_FAILURE
)
export const createCafeReferralCancelled = actionCreator(
  actionTypes.CREATE_CAFE_REFERRAL_CANCELLED
)

export const addCafePageInfo = (filterType, pageInfo) =>
  commonActionCreators.addPageInfo({
    entityType: ENTITY_TYPES.CAFES,
    filterType,
    pageInfo,
  })

export const loadCafesStarted = (filter, offset, limit) =>
  actionCreator(actionTypes.LOAD_ITEMS_STARTED)({
    filter,
    offset,
    limit,
  })

export const loadCafesSuccess = actionCreator(actionTypes.LOAD_ITEMS_SUCCESS)
export const loadCafesFailure = actionCreator(actionTypes.LOAD_ITEMS_FAILURE)
export const loadCafesCancelled = actionCreator(
  actionTypes.LOAD_ITEMS_CANCELLED
)

export const requestLoadItemsPerform = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_PERFORM
)
export const requestLoadItemsSuccess = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_SUCCESS
)
export const requestLoadItemsFailure = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_FAILURE
)
