import { ARTICLE_FILTER_TYPES, ENTITY_TYPES } from "../utils"
import { graphql, useStaticQuery } from "gatsby"
import {
  mapContentfulEdgesToNodes,
  mapEntityToUUID,
} from "../store/selectors/source-data-selector"

import { useEffect } from "react"

export const useArticlesLoader = (
  loadItemsSuccess,
  addEntities,
  addPageInfo,
  requestLoadArticlesPerform,
  requestLoadArticlesSuccess
) => {
  const { allContentfulArticle } = useStaticQuery(
    graphql`
      {
        allContentfulArticle(
          limit: 2000
          sort: { fields: publishedDate, order: DESC }
        ) {
          edges {
            node {
              id
              title
              slug
              blurb
              body {
                json
              }
              publishedDate(formatString: "MMM DD, YYYY")
              createdAt(formatString: "MMM DD, YYYY")
              image {
                fluid(quality: 100, maxWidth: 500, maxHeight: 400) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              author {
                id
                fullName
                bio
                avatar {
                  fluid(quality: 100, maxWidth: 40, maxHeight: 40) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    requestLoadArticlesPerform()
    const entities = mapContentfulEdgesToNodes(allContentfulArticle)
    const ids = mapEntityToUUID(entities)

    addEntities(ENTITY_TYPES.ARTICLES, entities)
    addPageInfo({
      entityType: ENTITY_TYPES.ARTICLES,
      filterType: ARTICLE_FILTER_TYPES.ALL,
      pageInfo: {
        count: ids.length,
        offset: 0,
      },
    })
    loadItemsSuccess({
      ids,
      filter: ARTICLE_FILTER_TYPES.ALL,
    })
    requestLoadArticlesSuccess()
  }, [])
}
