import { call, cancelled, delay, put, takeEvery } from "redux-saga/effects"
import {
  completeSignInCancelled,
  completeSignInFailure,
  completeSignInSuccess,
  requestCompleteSignInFailure,
  requestCompleteSignInPerform,
  requestCompleteSignInSuccess,
} from "../actions/actionCreators"
import { toastError, toastSuccess } from "../../../common/utils/"

import actionTypes from "../actions/actionTypes"
import { addRedirectTo } from "../actions/actionCreators"
import appRoutes from "../../../common/utils/appRoutes"
import { handleAuthentication } from "../../api"
import { loadUserStarted } from "../../../accounts/store/actions/actionCreators"
import { loadWalletItemsStarted } from "../../../walletItems/store/actions/actionCreators"
import { navigate } from "@reach/router"

function* instanceSaga(action) {
  try {
    yield put(requestCompleteSignInPerform())
    const completeSignInResult = yield call(handleAuthentication)
    yield put(requestCompleteSignInSuccess())
    yield put(completeSignInSuccess(completeSignInResult.tokens))
    yield put(loadUserStarted(completeSignInResult.user))
    yield put(loadWalletItemsStarted())
    yield delay(2000)
    navigate(action.payload || appRoutes.HOME)
    yield put(addRedirectTo(appRoutes.HOME))
    toastSuccess("Sign in successful!")
  } catch (error) {
    yield put(requestCompleteSignInFailure())
    yield put(completeSignInFailure(error))
    navigate(appRoutes.SIGN_IN, { replace: true })
    toastError("Failed to sign you in!")
  } finally {
    if (yield cancelled()) {
      yield put(completeSignInCancelled())
      navigate(appRoutes.SIGN_IN, { replace: true })
    }
  }
}

export function* completeSignInSaga() {
  yield takeEvery(actionTypes.COMPLETE_SIGN_IN_STARTED, instanceSaga)
}
