import { createUserSaga } from "./createUserSaga"
import { loadUserSaga } from "./loadUserSaga"
import { spawn } from "redux-saga/effects"
import { updateUserSaga } from "./updateUserSaga"

export default [
  spawn(loadUserSaga),
  spawn(updateUserSaga),
  spawn(createUserSaga),
]
