export default {
  ADD_PAGE_INFO: "BRANDS__ADD_PAGE_INFO",

  LOAD_ITEMS_STARTED: "BRANDS__LOAD_BRANDS_STARTED",
  LOAD_ITEMS_SUCCESS: "BRANDS__LOAD_BRANDS_SUCCESS",
  LOAD_ITEMS_FAILURE: "BRANDS__LOAD_BRANDS_FAILURE",
  LOAD_ITEMS_CANCELLED: "BRANDS__LOAD_BRANDS_CANCELLED",

  REQUEST_LOAD_ITEMS_PERFORM: "REQUEST_LOAD_BRANDS_PERFORM",
  REQUEST_LOAD_ITEMS_SUCCESS: "REQUEST_LOAD_BRANDS_SUCCESS",
  REQUEST_LOAD_ITEMS_FAILURE: "REQUEST_LOAD_BRANDS_FAILURE",
}
