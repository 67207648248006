export default {
  ADD_PAGE_INFO: "WALLET_ITEMS__ADD_PAGE_INFO",

  LOAD_ITEMS_STARTED: "WALLET_ITEMS__LOAD_WALLET_ITEMS_STARTED",
  LOAD_ITEMS_SUCCESS: "WALLET_ITEMS__LOAD_WALLET_ITEMS_SUCCESS",
  LOAD_ITEMS_FAILURE: "WALLET_ITEMS__LOAD_WALLET_ITEMS_FAILURE",
  LOAD_ITEMS_CANCELLED: "WALLET_ITEMS__LOAD_WALLET_ITEMS_CANCELLED",

  REQUEST_LOAD_ITEMS_PERFORM: "REQUEST_LOAD_WALLET_ITEMS_PERFORM",
  REQUEST_LOAD_ITEMS_SUCCESS: "REQUEST_LOAD_WALLET_ITEMS_SUCCESS",
  REQUEST_LOAD_ITEMS_FAILURE: "REQUEST_LOAD_WALLET_ITEMS_FAILURE",

  CREATE_WALLET_ITEM_STARTED: "WALLET_ITEMS__CREATE_WALLET_ITEM_STARTED",
  CREATE_WALLET_ITEM_SUCCESS: "WALLET_ITEMS__CREATE_WALLET_ITEM_SUCCESS",
  CREATE_WALLET_ITEM_FAILURE: "WALLET_ITEMS__CREATE_WALLET_ITEM_FAILURE",
  CREATE_WALLET_ITEM_CANCELLED: "WALLET_ITEMS__CREATE_WALLET_ITEM_CANCELLED",

  REQUEST_CREATE_WALLET_ITEM_PERFORM: "REQUEST_CREATE_WALLET_ITEM_PERFORM",
  REQUEST_CREATE_WALLET_ITEM_SUCCESS: "REQUEST_CREATE_WALLET_ITEM_SUCCESS",
  REQUEST_CREATE_WALLET_ITEM_FAILURE: "REQUEST_CREATE_WALLET_ITEM_FAILURE",

  REMOVE_WALLET_ITEM_STARTED: "WALLET_ITEMS__REMOVE_WALLET_ITEM_STARTED",
  REMOVE_WALLET_ITEM_SUCCESS: "WALLET_ITEMS__REMOVE_WALLET_ITEM_SUCCESS",
  REMOVE_WALLET_ITEM_FAILURE: "WALLET_ITEMS__REMOVE_WALLET_ITEM_FAILURE",
  REMOVE_WALLET_ITEM_CANCELLED: "WALLET_ITEMS__REMOVE_WALLET_ITEM_CANCELLED",

  REQUEST_REMOVE_WALLET_ITEM_PERFORM: "REQUEST_REMOVE_WALLET_ITEM_PERFORM",
  REQUEST_REMOVE_WALLET_ITEM_SUCCESS: "REQUEST_REMOVE_WALLET_ITEM_SUCCESS",
  REQUEST_REMOVE_WALLET_ITEM_FAILURE: "REQUEST_REMOVE_WALLET_ITEM_FAILURE",
}
