import { combineReducers } from "redux"
import contextReducer from "./context-reducer"
import locationReducer from "./location-reducer"
import tokenReducer from "./token-reducer"

export default combineReducers({
  tokens: tokenReducer,
  location: locationReducer,
  context: contextReducer,
})
