export default {
  LOAD_USER_STARTED: "USER__LOAD_STARTED",
  LOAD_USER_SUCCESS: "USER__LOAD_SUCCESS",
  LOAD_USER_FAILURE: "USER__LOAD_FAILURE",
  LOAD_USER_CANCELLED: "USER__LOAD_CANCELLED",

  REQUEST_LOAD_USER_PERFORM: "REQUEST_LOAD_USER_PERFORM",
  REQUEST_LOAD_USER_SUCCESS: "REQUEST_LOAD_USER_SUCCESS",
  REQUEST_LOAD_USER_FAILURE: "REQUEST_LOAD_USER_FAILURE",

  CREATE_USER_STARTED: "USER__CREATE_STARTED",
  CREATE_USER_SUCCESS: "USER__CREATE_SUCCESS",
  CREATE_USER_FAILURE: "USER__CREATE_FAILURE",
  CREATE_USER_CANCELLED: "USER__CREATE_CANCELLED",

  REQUEST_CREATE_USER_PERFORM: "REQUEST_CREATE_USER_PERFORM",
  REQUEST_CREATE_USER_SUCCESS: "REQUEST_CREATE_USER_SUCCESS",
  REQUEST_CREATE_USER_FAILURE: "REQUEST_CREATE_USER_FAILURE",

  UPDATE_USER_STARTED: "USER__UPDATE_STARTED",
  UPDATE_USER_SUCCESS: "USER__UPDATE_SUCCESS",
  UPDATE_USER_FAILURE: "USER__UPDATE_FAILURE",
  UPDATE_USER_CANCELLED: "USER__UPDATE_CANCELLED",

  REQUEST_UPDATE_USER_PERFORM: "REQUEST_UPDATE_USER_PERFORM",
  REQUEST_UPDATE_USER_SUCCESS: "REQUEST_UPDATE_USER_SUCCESS",
  REQUEST_UPDATE_USER_FAILURE: "REQUEST_UPDATE_USER_FAILURE",
}
