import actionTypes from "../../../cafes/store/actions/actionTypes"

const initialState = {
  cafes: [], // Google Place ID
}

const addItemToArray = (arr, item) => {
  if (!!item && !arr.includes(item)) {
    return [...arr, item]
  }
  return arr
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CAFES__CREATE_CAFE_REFERRAL_SUCCESS: {
      // Todo: referrals
      // return {
      //   ...state,
      //   cafes: addItemToArray(state.cafes, payload),
      // }
      return state
    }
    default:
      return state
  }
}
