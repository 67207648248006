import * as actionTypes from "./action-types"

import { actionCreator } from "../../utils/redux-helper"

export default {
  addError: actionCreator(actionTypes.ADD_ERROR),
  networkError: actionCreator(actionTypes.NETWORK_ERROR),
  startLoading: actionCreator(actionTypes.START_LOADING),
  stopLoading: actionCreator(actionTypes.STOP_LOADING),
  startSubmitting: actionCreator(actionTypes.START_SUBMITTING),
  stopSubmitting: actionCreator(actionTypes.STOP_SUBMITTING),
  addEntity: actionCreator(actionTypes.ADD_ENTITY),
  addPageInfo: actionCreator(actionTypes.ADD_PAGE_INFO),
  addEntities: (entityType, entities) =>
    actionCreator(actionTypes.ADD_ENTITIES)({ entityType, entities }),
  clearEntities: entityType =>
    actionCreator(actionTypes.CLEAR_ENTITIES)({ entityType }),
  requestError: actionCreator(actionTypes.REQUEST_ERROR),
  requestSuccess: actionCreator(actionTypes.REQUEST_SUCCESS),
}
