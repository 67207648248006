export default {
  SIGN_IN_STARTED: "AUTH__SIGN_IN_STARTED",
  SIGN_IN_SUCCESS: "AUTH__SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "AUTH__SIGN_IN_FAILURE",
  SIGN_IN_CANCELLED: "AUTH__SIGN_IN_CANCELLED",

  REQUEST_SIGN_IN_PERFORM: "REQUEST_SIGN_IN_PERFORM",
  REQUEST_SIGN_IN_SUCCESS: "REQUEST_SIGN_IN_SUCCESS",
  REQUEST_SIGN_IN_FAILURE: "REQUEST_SIGN_IN_FAILURE",

  SIGN_IN_CONFIRMATION_STARTED: "AUTH__SIGN_IN_CONFIRMATION_STARTED",
  SIGN_IN_CONFIRMATION_SUCCESS: "AUTH__SIGN_IN_CONFIRMATION_SUCCESS",
  SIGN_IN_CONFIRMATION_FAILURE: "AUTH__SIGN_IN_CONFIRMATION_FAILURE",
  SIGN_IN_CONFIRMATION_CANCELLED: "AUTH__SIGN_IN_CONFIRMATION_CANCELLED",

  REQUEST_SIGN_IN_CONFIRMATION_PERFORM: "REQUEST_SIGN_IN_CONFIRMATION_PERFORM",
  REQUEST_SIGN_IN_CONFIRMATION_SUCCESS: "REQUEST_SIGN_IN_CONFIRMATION_SUCCESS",
  REQUEST_SIGN_IN_CONFIRMATION_FAILURE: "REQUEST_SIGN_IN_CONFIRMATION_FAILURE",

  SIGN_OUT_STARTED: "AUTH__SIGN_OUT_STARTED",
  SIGN_OUT_SUCCESS: "AUTH__SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "AUTH__SIGN_OUT_FAILURE",
  SIGN_OUT_CANCELLED: "AUTH__SIGN_OUT_CANCELLED",

  REQUEST_SIGN_OUT_PERFORM: "REQUEST_SIGN_OUT_PERFORM",
  REQUEST_SIGN_OUT_SUCCESS: "REQUEST_SIGN_OUT_SUCCESS",
  REQUEST_SIGN_OUT_FAILURE: "REQUEST_SIGN_OUT_FAILURE",

  CHECK_SESSION_STARTED: "AUTH__CHECK_SESSION_STARTED",
  CHECK_SESSION_SUCCESS: "AUTH__CHECK_SESSION_SUCCESS",
  CHECK_SESSION_FAILURE: "AUTH__CHECK_SESSION_FAILURE",
  CHECK_SESSION_CANCELLED: "AUTH__CHECK_SESSION_CANCELLED",

  REQUEST_CHECK_SESSION_PERFORM: "REQUEST_CHECK_SESSION_PERFORM",
  REQUEST_CHECK_SESSION_SUCCESS: "REQUEST_CHECK_SESSION_SUCCESS",
  REQUEST_CHECK_SESSION_FAILURE: "REQUEST_CHECK_SESSION_FAILURE",

  COMPLETE_SIGN_IN_STARTED: "AUTH__COMPLETE_SIGN_IN_STARTED",
  COMPLETE_SIGN_IN_SUCCESS: "AUTH__COMPLETE_SIGN_IN_SUCCESS",
  COMPLETE_SIGN_IN_FAILURE: "AUTH__COMPLETE_SIGN_IN_FAILURE",
  COMPLETE_SIGN_IN_CANCELLED: "AUTH__COMPLETE_SIGN_IN_CANCELLED",

  REQUEST_COMPLETE_SIGN_IN_PERFORM: "REQUEST_COMPLETE_SIGN_IN_PERFORM",
  REQUEST_COMPLETE_SIGN_IN_SUCCESS: "REQUEST_COMPLETE_SIGN_IN_SUCCESS",
  REQUEST_COMPLETE_SIGN_IN_FAILURE: "REQUEST_COMPLETE_SIGN_IN_FAILURE",

  ADD_TOKENS: "TOKENS__ADD",
  ADD_DEVICE_ID: "DEVICE_ID__ADD",
  ADD_REDIRECT_TO: "REDIRECT_TO__ADD",
  ADD_ENV_CONTEXT: "ENV_CONTEXT__ADD",
}
