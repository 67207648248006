import actionTypes from "../../actions/actionTypes"
import { createAction } from "redux-api-middleware"

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
}

export default data =>
  createAction({
    headers,
    endpoint: process.env.GATSBY_SLACK_WEBHOOK,
    method: "POST",
    body: JSON.stringify(data),
    types: [
      actionTypes.REQUEST_CREATE_CAFE_REFERRAL_PERFORM,
      {
        type: actionTypes.REQUEST_CREATE_CAFE_REFERRAL_SUCCESS,
        payload: true,
      },
      actionTypes.REQUEST_CREATE_CAFE_REFERRAL_FAILURE,
    ],
  })
