import get from "lodash/get"
import pick from "lodash/pick"

export function parseReferrerResult(data) {
  const { name, email } = data

  return {
    blocks: [
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*You've received a request for a new cafe :coffee:*`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Contact Name: *${name || ""}*`,
        },
      },

      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Contact Email: *${email || ""}*`,
        },
      },
    ],
  }
}

export function parseSearchResult(results, data) {
  const fields = pick(results, [
    "formatted_address",
    "structured_formatting",
    "geometry",
    "place_id",
    "description",
  ])

  const { contact, email, phone, name } = data

  const latitude = get(fields, "geometry.location.lat", "")
  const longitude = get(fields, "geometry.location.lng", "")

  return {
    blocks: [
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*You've received a request for a new cafe :coffee:*`,
        },
      },

      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Name: *${get(fields, "structured_formatting.main_text", "")}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Address: *${get(fields, "formatted_address", "")}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Description: *${get(fields, "description", "")}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Place ID: *${get(fields, "place_id", "")}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Latitude: *${latitude()}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Longitude: *${longitude()}*`,
        },
      },

      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Contact: *${contact || ""}*`,
        },
      },

      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Contact Email: *${email || ""}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Contact Phone: *${phone || ""}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Referrer Name: *${name || ""}*`,
        },
      },
    ],
  }
}
