import { slugifyBrand, slugifyOffer } from "./slugifier"

import React from "react"
import camelcaseKeys from "camelcase-keys"
import get from "lodash/get"
import keys from "lodash/keys"
import path from "path"
import queryString from "query-string"
import snakeCase from "lodash/snakeCase"
import snakeCaseKeys from "snakecase-keys"
import { toast } from "react-toastify"

export const DEFAULT_PAGE_SIZE =
  parseInt(process.env.GATSBY_DEFAULT_PAGE_SIZE) || 40

export const AnalyticsContext = React.createContext({})
export const BuildContext = React.createContext({})

export { slugifyBrand, slugifyOffer }

export const snakeify = values => snakeCaseKeys(values, { deep: true })
export const camelize = values => camelcaseKeys(values, { deep: true })

export const ENTITY_TYPES = {
  OFFERS: "offers",
  BRANDS: "brands",
  ARTICLES: "articles",
  CAFES: "cafes",
  WALLET_ITEMS: "walletItems",
}

export const OFFER_FILTER_TYPES = {
  ALL: "allOffers",
  WALLET: "walletOffers",
  SAVED: "savedOffers",
}

export const BRAND_FILTER_TYPES = {
  ALL: "allBrands",
  WALLET: "walletBrands",
}

export const CAFE_FILTER_TYPES = {
  ALL: "allCafes",
}

export const ARTICLE_FILTER_TYPES = {
  ALL: "allArticles",
}

export const WALLET_ITEM_FILTER_TYPES = {
  ALL: "allWalletItems",
}

export const ENTITY_ITEMS = {
  [ENTITY_TYPES.CAFES]: {
    defaultFilter: CAFE_FILTER_TYPES.ALL,
    filters: {
      [CAFE_FILTER_TYPES.ALL]: {
        label: "All Cafes",
      },
    },
  },
  [ENTITY_TYPES.OFFERS]: {
    defaultFilter: OFFER_FILTER_TYPES.ALL,
    filters: {
      [OFFER_FILTER_TYPES.ALL]: {
        label: "All Offers",
      },
      [OFFER_FILTER_TYPES.WALLET]: {
        label: "My Offers",
      },
      [OFFER_FILTER_TYPES.SAVED]: {
        label: "Saved Offers",
      },
    },
  },
  [ENTITY_TYPES.BRANDS]: {
    defaultFilter: BRAND_FILTER_TYPES.ALL,
    filters: {
      [BRAND_FILTER_TYPES.ALL]: {
        label: "All Brands",
      },
      [BRAND_FILTER_TYPES.WALLET]: {
        label: "Wallet",
      },
    },
  },
  [ENTITY_TYPES.ARTICLES]: {
    defaultFilter: ARTICLE_FILTER_TYPES.ALL,
    filters: {
      [ARTICLE_FILTER_TYPES.ALL]: {
        label: "All Articles",
      },
    },
  },
  [ENTITY_TYPES.WALLET_ITEMS]: {
    defaultFilter: WALLET_ITEM_FILTER_TYPES.ALL,
    filters: {
      [WALLET_ITEM_FILTER_TYPES.ALL]: {
        label: "All Wallet Items",
      },
    },
  },
}

export const RouteInfo = {
  ABOUT: "/about",
  ACCOUNT: "/app/account",
  ARTICLES: "/articles",
  BRANDS: "/brands",
  CAFES_ABOUT: "/cafes/about",
  CAFES_REFERRAL: "/cafes/new",
  CAFES: "/cafes",
  CALLBACK: "/app/callback",
  COMPETITIONS: "/competitions",
  HOME: "/",
  OFFERS: "/offers",
  SIGN_IN_CODE: "/app/signin/code",
  SIGN_IN: "/app/signin",
  SIGN_OUT: "/app/signout",
}

export const entityTypes = () => Object.keys(ENTITY_ITEMS)

export const entityFilterlabel = (entityType, filter) => {
  const entity = get(ENTITY_ITEMS, entityType)
  const filters = entity.filters
  return get(filters[filter], "label")
}

export const entityDefaultFilter = entityType => {
  const entity = get(ENTITY_ITEMS, entityType)
  return entity.defaultFilter
}

export const entityFilterOptions = entityType => {
  const entity = get(ENTITY_ITEMS, entityType)
  const filters = entity.filters
  return Object.keys(filters).map(filter => ({
    value: filter,
    label: get(filters[filter], "label"),
  }))
}

/**
 * Returns the url
 * @param {string} basePath - Any url base path
 * @param {Object} params - The query params to be appended
 * @returns {string} - The constructored url string with query params or undefined
 */
export function buildURLString(basePath, params = {}) {
  try {
    const url = new URL(basePath)
    keys(params).forEach(key => url.searchParams.append(key, params[key]))
    return url.toString()
  } catch {
    return undefined
  }
}

/**
 * Returns the app deeplink (https://whatsmine.app.link/reward?rewardId=123)
 * @param {string} baseDomain - Branch domain (https://whatsmine.app.link)
 * @param {string} id - The name of the resource [reward|program]
 * @param {Object} params - The query params [rewardId|programId]
 * @returns {string} - The constructored deeplink string
 */
export function buildAppDeeplink(id, params) {
  const basePath = path.join(process.env.GATSBY_BRANCH_DOMAIN, id)
  return buildURLString(basePath, params)
}

export const xysCalc = (x, y) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.03,
]
export const interpolateTrans = (x, y, s) => `perspective(500px) scale(${s})`

export const toastSuccess = text => {
  toast.success(text, {
    className: "text-white p-4",
    bodyClassName: "text-white text-base font-semibold px-4",
  })
}

export const toastError = text => {
  toast.error(text, {
    className: "text-white p-4 w-full m-w-100",
    bodyClassName: "text-white text-base font-semibold px-4",
  })
}

export const BRANCH_DEEPLINK_TYPES = {
  REWARD: "reward",
  PROGRAM: "program",
}

export const arrayToObject = (array, keyField = "uuid") =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {})

export const nextOffset = offset => offset + DEFAULT_PAGE_SIZE

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value)
}

export function serializeFilterToQueryString(filterTypes, filter) {
  const f = getKeyByValue(filterTypes, filter)
  return snakeCase(f)
}

export function deserializeFilterFromQueryString(location, filterTypes) {
  const filterQuery = queryString.parse(location.search)
  return get(filterQuery, "q", "all").toUpperCase()
}
