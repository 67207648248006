import actionTypes from "../actions/actionTypes"

const initialState = {
  expiresAt: null,
  idToken: null,
  accessToken: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.COMPLETE_SIGN_IN_SUCCESS:
    case actionTypes.CHECK_SESSION_SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
