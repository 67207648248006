import { ENTITY_TYPES } from "../../../common/utils"
import { actionCreator } from "../../../common/utils/redux-helper"
import actionTypes from "./actionTypes"
import commonActionCreators from "../../../common/store/actions/action-creators"

export const addBrandPageInfo = (filterType, pageInfo) =>
  commonActionCreators.addPageInfo({
    entityType: ENTITY_TYPES.BRANDS,
    filterType,
    pageInfo,
  })

export const loadBrandsStarted = (filter, offset, limit) =>
  actionCreator(actionTypes.LOAD_ITEMS_STARTED)({
    filter,
    offset,
    limit,
  })

export const loadBrandsSuccess = actionCreator(actionTypes.LOAD_ITEMS_SUCCESS)
export const loadBrandsFailure = actionCreator(actionTypes.LOAD_ITEMS_FAILURE)
export const loadBrandsCancelled = actionCreator(
  actionTypes.LOAD_ITEMS_CANCELLED
)

export const requestLoadItemsPerform = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_PERFORM
)
export const requestLoadItemsSuccess = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_SUCCESS
)
export const requestLoadItemsFailure = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_FAILURE
)

export const createWalletItemStarted = actionCreator(
  actionTypes.CREATE_WALLET_ITEM_STARTED
)
export const createWalletItemSuccess = actionCreator(
  actionTypes.CREATE_WALLET_ITEM_SUCCESS
)
export const createWalletItemFailure = actionCreator(
  actionTypes.CREATE_WALLET_ITEM_FAILURE
)
export const createWalletItemCancelled = actionCreator(
  actionTypes.CREATE_WALLET_ITEM_CANCELLED
)

export const removeWalletItemStarted = actionCreator(
  actionTypes.REMOVE_WALLET_ITEM_STARTED
)
export const removeWalletItemSuccess = actionCreator(
  actionTypes.REMOVE_WALLET_ITEM_SUCCESS
)
export const removeWalletItemFailure = actionCreator(
  actionTypes.REMOVE_WALLET_ITEM_FAILURE
)
export const removeWalletItemCancelled = actionCreator(
  actionTypes.REMOVE_WALLET_ITEM_CANCELLED
)
