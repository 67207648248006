import { ENTITY_TYPES } from "../../../common/utils"
import { actionCreator } from "../../../common/utils/redux-helper"
import actionTypes from "./actionTypes"
import commonActionCreators from "../../../common/store/actions/action-creators"

export const addArticlePageInfo = (filterType, pageInfo) =>
  commonActionCreators.addPageInfo({
    entityType: ENTITY_TYPES.ARTICLES,
    filterType,
    pageInfo,
  })

export const loadArticlesStarted = (filter, offset, limit) =>
  actionCreator(actionTypes.LOAD_ITEMS_STARTED)({
    filter,
    offset,
    limit,
  })
export const loadArticlesSuccess = actionCreator(actionTypes.LOAD_ITEMS_SUCCESS)
export const loadArticlesFailure = actionCreator(actionTypes.LOAD_ITEMS_FAILURE)
export const loadArticlesCancelled = actionCreator(
  actionTypes.LOAD_ITEMS_CANCELLED
)

export const requestLoadItemsPerform = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_PERFORM
)
export const requestLoadItemsSuccess = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_SUCCESS
)
export const requestLoadItemsFailure = actionCreator(
  actionTypes.REQUEST_LOAD_ITEMS_FAILURE
)
