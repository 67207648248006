import { createAction, getJSON } from "redux-api-middleware"

import actionTypes from "../../actions/actionTypes"
import { camelize } from "../../../../common/utils"

export default payload =>
  createAction({
    endpoint: `/users/`,
    method: "PATCH",
    body: JSON.stringify(payload.user),
    types: [
      actionTypes.REQUEST_UPDATE_USER_PERFORM,
      {
        type: actionTypes.REQUEST_UPDATE_USER_SUCCESS,
        payload: (action, state, res) =>
          getJSON(res).then(json => camelize(json)),
      },
      actionTypes.REQUEST_UPDATE_USER_FAILURE,
    ],
  })
