export default {
  ADD_PAGE_INFO: "OFFERS__ADD_PAGE_INFO",

  LOAD_ITEMS_STARTED: "OFFERS__LOAD_OFFERS_STARTED",
  LOAD_ITEMS_SUCCESS: "OFFERS__LOAD_OFFERS_SUCCESS",
  LOAD_ITEMS_FAILURE: "OFFERS__LOAD_OFFERS_FAILURE",
  LOAD_ITEMS_CANCELLED: "OFFERS__LOAD_OFFERS_CANCELLED",

  REQUEST_LOAD_ITEMS_PERFORM: "REQUEST_LOAD_OFFERS_PERFORM",
  REQUEST_LOAD_ITEMS_SUCCESS: "REQUEST_LOAD_OFFERS_SUCCESS",
  REQUEST_LOAD_ITEMS_FAILURE: "REQUEST_LOAD_OFFERS_FAILURE",
}
