import { createWalletItemSaga } from "./createWalletItemSaga"
import { loadWalletItemsSaga } from "./loadWalletItemsSaga"
import { removeWalletItemSaga } from "./removeWalletItemSaga"
import { spawn } from "redux-saga/effects"

export default [
  spawn(loadWalletItemsSaga),
  spawn(createWalletItemSaga),
  spawn(removeWalletItemSaga),
]
