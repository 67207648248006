import { call, cancelled, put, putResolve, takeEvery } from "redux-saga/effects"
import {
  loadOffersCancelled,
  loadOffersFailure,
  loadOffersSuccess,
} from "../actions/actionCreators"

import { ENTITY_TYPES } from "../../../common/utils"
import actionTypes from "../actions/actionTypes"
import commonActionCreators from "../../../common/store/actions/action-creators"
import loadOffersAction from "./api/loadOffersAction"

export function* loadOffers(payload) {
  try {
    return yield putResolve(loadOffersAction(payload))
  } catch (error) {
    return null
  } finally {
    if (yield cancelled()) {
      yield put(loadOffersCancelled())
      // ... put special cancellation handling code here
    }
  }
}

function* instanceSaga(action) {
  const results = yield call(loadOffers, action.payload)
  if (!results || results.error) {
    yield put(loadOffersFailure())
  } else {
    const { ids, count } = results.payload

    yield put(
      loadOffersSuccess({
        ids,
        filter: action.payload.filter,
      })
    )

    yield put(
      commonActionCreators.addPageInfo({
        entityType: ENTITY_TYPES.OFFERS,
        filterType: action.payload.filter,
        pageInfo: {
          count,
          offset: action.payload.offset,
        },
      })
    )
  }
}

export function* loadOffersSaga() {
  yield takeEvery(actionTypes.LOAD_ITEMS_STARTED, instanceSaga)
}
