export default {
  CREATE_CAFE_REFERRAL_STARTED: "CAFES__CREATE_CAFE_REFERRAL_STARTED",
  CREATE_CAFE_REFERRAL_SUCCESS: "CAFES__CREATE_CAFE_REFERRAL_SUCCESS",
  CREATE_CAFE_REFERRAL_FAILURE: "CAFES__CREATE_CAFE_REFERRAL_FAILURE",
  CREATE_CAFE_REFERRAL_CANCELLED: "CAFES__CREATE_CAFE_REFERRAL_CANCELLED",

  REQUEST_CREATE_CAFE_REFERRAL_PERFORM: "REQUEST_CREATE_CAFE_REFERRAL_PERFORM",
  REQUEST_CREATE_CAFE_REFERRAL_SUCCESS: "REQUEST_CREATE_CAFE_REFERRAL_SUCCESS",
  REQUEST_CREATE_CAFE_REFERRAL_FAILURE: "REQUEST_CREATE_CAFE_REFERRAL_FAILURE",

  LOAD_ITEMS_STARTED: "CAFES__LOAD_CAFES_STARTED",
  LOAD_ITEMS_SUCCESS: "CAFES__LOAD_CAFES_SUCCESS",
  LOAD_ITEMS_FAILURE: "CAFES__LOAD_CAFES_FAILURE",
  LOAD_ITEMS_CANCELLED: "CAFES__LOAD_CAFES_CANCELLED",

  REQUEST_LOAD_ITEMS_PERFORM: "REQUEST_LOAD_CAFES_PERFORM",
  REQUEST_LOAD_ITEMS_SUCCESS: "REQUEST_LOAD_CAFES_SUCCESS",
  REQUEST_LOAD_ITEMS_FAILURE: "REQUEST_LOAD_CAFES_FAILURE",
}
