import { createAction, getJSON } from "redux-api-middleware"

import { OFFER_FILTER_TYPES } from "../../../../common/utils"
import actionTypes from "../../actions/actionTypes"
import get from "lodash/get"
import { mapEntityToUUID } from "../../../../common/store/selectors/source-data-selector"
import queryString from "query-string"

const QUERIES = {
  [OFFER_FILTER_TYPES.WALLET]: {
    path: "/rewards/",
    params: {
      foruser: true,
    },
  },
  [OFFER_FILTER_TYPES.SAVED]: {
    path: "/rewards/favourites/",
  },
}

const mapSuccessPayload = (action, state, res) => {
  return getJSON(res).then(json => {
    const data = get(json, "results.other")
    const count = get(json, "count")

    const ids = mapEntityToUUID(data).filter(uuid => !!uuid)
    return { ids, count }
  })
}

export default payload => {
  const { filter, ...rest } = payload
  const baseQuery = QUERIES[filter]

  const queryparams = {
    ...baseQuery.params,
    ...rest,
  }

  return createAction({
    endpoint: `${baseQuery.path}?${queryString.stringify(queryparams)}`,
    method: "GET",
    types: [
      actionTypes.REQUEST_LOAD_ITEMS_PERFORM,
      {
        type: actionTypes.REQUEST_LOAD_ITEMS_SUCCESS,
        payload: mapSuccessPayload,
      },
      actionTypes.REQUEST_LOAD_ITEMS_FAILURE,
    ],
  })
}
