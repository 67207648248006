import * as contextSelectors from "../../../auth/store/selectors/context-selector"
import * as tokenSelector from "../../../auth/store/selectors/token-selector"

import { RSAA, isRSAA } from "redux-api-middleware"

import has from "lodash/has"
import set from "lodash/set"

export const API_REQUEST_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
}

export default store => next => async action => {
  const callApi = action[RSAA]
  // Check if this action is a redux-api-middleware action.
  if (isRSAA(action)) {
    // Prepend API_URL to endpoint if it does not already contain a valid base URL.
    if (!/^((http|https|ftp):\/\/)/i.test(callApi.endpoint)) {
      callApi.endpoint = `${process.env.GATSBY_MINE_SERVICE_API_URL}${callApi.endpoint}`
    }

    const isThirdPartyApi =
      callApi.endpoint.indexOf(process.env.GATSBY_MINE_SERVICE_API_URL) !== 0

    if (!has(callApi, "headers")) {
      callApi.headers = {}
    }

    if (!has(callApi, "headers.Content-Type")) {
      set(callApi, "headers.Content-Type", API_REQUEST_HEADERS["Content-Type"])
    }

    if (!isThirdPartyApi) {
      if (!has(callApi, "headers.Accept")) {
        set(callApi, "headers.Accept", API_REQUEST_HEADERS.Accept)
      }
      if (!has(callApi, "headers.X-Device-ID")) {
        const deviceID = contextSelectors.deviceID$(store.getState())
        set(callApi, "headers.X-Device-ID", deviceID)
      }

      const accessToken = tokenSelector.accessToken$(store.getState())
      if (!has(callApi, "headers.Authorization") && accessToken) {
        callApi.headers.Authorization = `Bearer ${accessToken}`
      }
    }
  }
  return next(action)
}
