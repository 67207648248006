const slugify = require("slugify")

const get = require("lodash/get")

const slugifyOptions = { lower: true, remove: /[*+~.()'"!:@?#]/g }

module.exports = {
  slugifyBrand: function(brand) {
    return slugify(get(brand, "name", "error"), slugifyOptions)
  },
  slugifyOffer: function(offer) {
    return slugify(get(offer, "short_title", "error"), slugifyOptions)
  },
}
