import { checkSessionSaga } from "./checkSessionSaga"
import { completeSignInSaga } from "./completeSignInSaga"
import { signInConfirmationSaga } from "./signInConfirmationSaga"
import { signInSaga } from "./signInSaga"
import { signOutSaga } from "./signOutSaga"
import { spawn } from "redux-saga/effects"

export default [
  spawn(signInSaga),
  spawn(signInConfirmationSaga),
  spawn(completeSignInSaga),
  spawn(checkSessionSaga),
  spawn(signOutSaga),
]
