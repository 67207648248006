import {
  call,
  cancelled,
  fork,
  put,
  putResolve,
  take,
} from "redux-saga/effects"
import {
  createUserStarted,
  loadUserCancelled,
  loadUserFailure,
  loadUserSuccess,
} from "../actions/actionCreators"

import actionTypes from "../actions/actionTypes"
import loadUserAction from "./api/loadUserAction"

function* loadUser(payload) {
  try {
    return yield putResolve(loadUserAction())
  } catch (error) {
    return null
  } finally {
    if (yield cancelled()) {
      yield put(loadUserCancelled())
      // ... put special cancellation handling code here
    }
  }
}

function* instanceSaga(payload) {
  const result = yield call(loadUser)

  if (!result || result.error) {
    yield put(loadUserFailure(result.payload))
  } else {
    yield put(loadUserSuccess(result.payload))
  }
}

export function* loadUserSaga() {
  while (true) {
    const { payload } = yield take(actionTypes.LOAD_USER_STARTED)
    yield fork(instanceSaga, payload)
    const action = yield take([
      actionTypes.LOAD_USER_FAILURE,
      actionTypes.LOAD_USER_SUCCESS, // fall through on success
    ])

    // if we fail to load with a "Not Found" status
    // create the user for this auth.
    if (
      action.type === actionTypes.LOAD_USER_FAILURE &&
      action.payload.status === 404
    ) {
      yield put(createUserStarted(payload))
    }
  }
}
