import auth0 from "auth0-js"
import jwtDecode from "jwt-decode"

const isBrowser = typeof window !== "undefined"

export const errorStatuses = [503, 408]

export const Auth0Factory = (function() {
  let instance
  return {
    getInstance: function() {
      if (isBrowser && instance == null) {
        instance = new auth0.WebAuth({
          domain: process.env.GATSBY_AUTH0_DOMAIN,
          clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
          redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
          audience: process.env.GATSBY_AUTH0_AUDIENCE,
          responseType: "token id_token",
          scope: "openid profile email offline_access",
        })
        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = null
      }
      return instance
    },
  }
})()

export function isExpiredJWT(jwt) {
  if (jwt) {
    const decodeJWT = jwtDecode(jwt) // expiry in seconds since epoch
    const now = Date.now() / 1000 // time now in seconds since epoch
    return now > decodeJWT.exp
  } else {
    return true
  }
}

export function authenticated(jwt) {
  return !isExpiredJWT(jwt)
}

function mapUserData(payload) {
  const { given_name, family_name, email, picture } = payload || {}
  return {
    email,
    firstName: given_name,
    lastName: family_name,
    photo: picture,
  }
}

export function getAuthResult(result) {
  const { accessToken, idToken, expiresIn, idTokenPayload, refreshToken } =
    result || {}
  const expiresAt = expiresIn * 1000 + new Date().getTime()
  return {
    user: mapUserData(idTokenPayload),
    tokens: {
      expiresAt,
      idToken,
      accessToken,
      refreshToken,
    },
  }
}

export function authorizeWithGoogle() {
  Auth0Factory.getInstance().authorize({
    client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
    redirect_uri: process.env.GATSBY_AUTH0_CALLBACK,
    response_type: "token id_token",
    connection: "google-oauth2",
  })
}

export function authorizeWithFacebook() {
  Auth0Factory.getInstance().authorize({
    client_id: process.env.GATSBY_AUTH0_CLIENT_ID,
    redirect_uri: process.env.GATSBY_AUTH0_CALLBACK,
    response_type: "token id_token",
    connection: "facebook",
  })
}
