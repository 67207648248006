import actionTypes from "../../actions/actionTypes"
import { createAction } from "redux-api-middleware"

export default payload =>
  createAction({
    endpoint: `/wallet-items/`,
    method: "POST",
    body: JSON.stringify(payload),
    types: [
      actionTypes.REQUEST_CREATE_WALLET_ITEM_PERFORM,
      actionTypes.REQUEST_CREATE_WALLET_ITEM_SUCCESS,
      actionTypes.REQUEST_CREATE_WALLET_ITEM_FAILURE,
    ],
  })
