import accountReducer from "../../../accounts/store/reducers"
import actionTypes from "../../../auth/store/actions/actionTypes"
import articleReducer from "../../../articles/store/reducers"
import authReducer from "../../../auth/store/reducers"
import brandReducer from "../../../brands/store/reducers"
import cafeReducer from "../../../cafes/store/reducers"
import { combineReducers } from "redux"
import entitiesReducer from "./entities-reducer"
import likesReducer from "../../../likes/store/reducers"
import loadingReducer from "./loadingReducer"
import offerReducer from "../../../offers/store/reducers"
import pageInfoReducer from "./page-info-reducer"
import referralsReducers from "../../../referrals/store/reducers"
import searchReducer from "../../../search/store/reducers"
import walletItemReducer from "../../../walletItems/store/reducers"

const appReducer = combineReducers({
  account: accountReducer,
  articles: articleReducer,
  auth: authReducer,
  brands: brandReducer,
  cafes: cafeReducer,
  entities: entitiesReducer,
  likes: likesReducer,
  loading: loadingReducer,
  offers: offerReducer,
  pageInfo: pageInfoReducer,
  referrals: referralsReducers,
  search: searchReducer,
  walletItems: walletItemReducer,
})

const rootReducer = (state, action) => {
  if (action.type === actionTypes.SIGN_OUT_STARTED) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
