import {
  call,
  cancelled,
  put,
  putResolve,
  takeLatest,
} from "redux-saga/effects"
import {
  createCafeReferralCancelled,
  createCafeReferralFailure,
  createCafeReferralSuccess,
} from "../actions/actionCreators"
import { toastError, toastSuccess } from "../../../common/utils"

import actionTypes from "../actions/actionTypes"
import createCafeReferralAction from "./api/createCafeReferralAction"
import { parseReferrerResult } from "../../utils"

export function* createCafeReferral(payload) {
  try {
    return yield putResolve(createCafeReferralAction(payload))
  } catch (error) {
    yield put(createCafeReferralFailure(error))
    toastError("Failed to sent your request!")
    return error
  } finally {
    if (yield cancelled()) {
      yield put(createCafeReferralCancelled())
      // ... put special cancellation handling code here
    }
  }
}

function* instanceSaga(action) {
  const { data } = action.payload
  const payload = parseReferrerResult(data)
  const results = yield call(createCafeReferral, payload)
  if (results && results.payload) {
    toastSuccess("Successfully sent your request")
    yield put(createCafeReferralSuccess())
  }
}

export function* createCafeReferralSaga() {
  yield takeLatest(actionTypes.CREATE_CAFE_REFERRAL_STARTED, instanceSaga)
}
