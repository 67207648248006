import { actionCreator } from "../../../common/utils/redux-helper"
import actionTypes from "./actionTypes"

// Sign In
export const signInStarted = actionCreator(actionTypes.SIGN_IN_STARTED)
export const signInSuccess = actionCreator(actionTypes.SIGN_IN_SUCCESS)
export const signInFailure = actionCreator(actionTypes.SIGN_IN_FAILURE)
export const signInCancelled = actionCreator(actionTypes.SIGN_IN_CANCELLED)

export const requestSignInPerform = actionCreator(
  actionTypes.REQUEST_SIGN_IN_PERFORM
)
export const requestSignInSuccess = actionCreator(
  actionTypes.REQUEST_SIGN_IN_SUCCESS
)
export const requestSignInFailure = actionCreator(
  actionTypes.REQUEST_SIGN_IN_FAILURE
)

// Sign Up Confirmation
export const signInConfirmationStarted = actionCreator(
  actionTypes.SIGN_IN_CONFIRMATION_STARTED
)
export const signInConfirmationSuccess = actionCreator(
  actionTypes.SIGN_IN_CONFIRMATION_SUCCESS
)
export const signInConfirmationFailure = actionCreator(
  actionTypes.SIGN_IN_CONFIRMATION_FAILURE
)
export const signInConfirmationCancelled = actionCreator(
  actionTypes.SIGN_IN_CONFIRMATION_CANCELLED
)

export const requestSignInConfirmationPerform = actionCreator(
  actionTypes.REQUEST_SIGN_IN_CONFIRMATION_PERFORM
)
export const requestSignInConfirmationSuccess = actionCreator(
  actionTypes.REQUEST_SIGN_IN_CONFIRMATION_SUCCESS
)
export const requestSignInConfirmationFailure = actionCreator(
  actionTypes.REQUEST_SIGN_IN_CONFIRMATION_FAILURE
)

// Complete Sign In
export const completeSignInStarted = actionCreator(
  actionTypes.COMPLETE_SIGN_IN_STARTED
)
export const completeSignInSuccess = actionCreator(
  actionTypes.COMPLETE_SIGN_IN_SUCCESS
)
export const completeSignInFailure = actionCreator(
  actionTypes.COMPLETE_SIGN_IN_FAILURE
)
export const completeSignInCancelled = actionCreator(
  actionTypes.COMPLETE_SIGN_IN_CANCELLED
)

export const requestCompleteSignInPerform = actionCreator(
  actionTypes.REQUEST_COMPLETE_SIGN_IN_PERFORM
)
export const requestCompleteSignInSuccess = actionCreator(
  actionTypes.REQUEST_COMPLETE_SIGN_IN_SUCCESS
)
export const requestCompleteSignInFailure = actionCreator(
  actionTypes.REQUEST_COMPLETE_SIGN_IN_FAILURE
)

// Sign Out
export const signOutStarted = actionCreator(actionTypes.SIGN_OUT_STARTED)
export const signOutSuccess = actionCreator(actionTypes.SIGN_OUT_SUCCESS)
export const signOutFailure = actionCreator(actionTypes.SIGN_OUT_FAILURE)
export const signOutCancelled = actionCreator(actionTypes.SIGN_OUT_CANCELLED)

export const requestSignOutPerform = actionCreator(
  actionTypes.REQUEST_SIGN_OUT_PERFORM
)
export const requestSignOutSuccess = actionCreator(
  actionTypes.REQUEST_SIGN_OUT_SUCCESS
)
export const requestSignOutFailure = actionCreator(
  actionTypes.REQUEST_SIGN_OUT_FAILURE
)

// Check Session
export const checkSessionStarted = actionCreator(
  actionTypes.CHECK_SESSION_STARTED
)
export const checkSessionSuccess = actionCreator(
  actionTypes.CHECK_SESSION_SUCCESS
)
export const checkSessionFailure = actionCreator(
  actionTypes.CHECK_SESSION_FAILURE
)
export const checkSessionCancelled = actionCreator(
  actionTypes.CHECK_SESSION_CANCELLED
)

export const requestCheckSessionPerform = actionCreator(
  actionTypes.REQUEST_CHECK_SESSION_PERFORM
)
export const requestCheckSessionSuccess = actionCreator(
  actionTypes.REQUEST_CHECK_SESSION_SUCCESS
)
export const requestCheckSessionFailure = actionCreator(
  actionTypes.REQUEST_CHECK_SESSION_FAILURE
)

// Auth
export const addDeviceID = actionCreator(actionTypes.ADD_DEVICE_ID)
export const addEnvContext = actionCreator(actionTypes.ADD_ENV_CONTEXT)
export const addRedirectTo = actionCreator(actionTypes.ADD_REDIRECT_TO)
