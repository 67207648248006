import { actionCreator } from "../../../common/utils/redux-helper"
import actionTypes from "./actionTypes"

export const loadUserStarted = actionCreator(actionTypes.LOAD_USER_STARTED)
export const loadUserSuccess = actionCreator(actionTypes.LOAD_USER_SUCCESS)
export const loadUserFailure = actionCreator(actionTypes.LOAD_USER_FAILURE)
export const loadUserCancelled = actionCreator(actionTypes.LOAD_USER_CANCELLED)

export const updateUserStarted = actionCreator(actionTypes.UPDATE_USER_STARTED)
export const updateUserSuccess = actionCreator(actionTypes.UPDATE_USER_SUCCESS)
export const updateUserFailure = actionCreator(actionTypes.UPDATE_USER_FAILURE)
export const updateUserCancelled = actionCreator(
  actionTypes.UPDATE_USER_CANCELLED
)

export const createUserStarted = actionCreator(actionTypes.CREATE_USER_STARTED)
export const createUserSuccess = actionCreator(actionTypes.CREATE_USER_SUCCESS)
export const createUserFailure = actionCreator(actionTypes.CREATE_USER_FAILURE)
export const createUserCancelled = actionCreator(
  actionTypes.CREATE_USER_CANCELLED
)

export const requestCreateUserPerform = actionCreator(
  actionTypes.REQUEST_CREATE_USER_PERFORM
)
export const requestCreateUserSuccess = actionCreator(
  actionTypes.REQUEST_CREATE_USER_SUCCESS
)
export const requestCreateUserFailure = actionCreator(
  actionTypes.REQUEST_CREATE_USER_FAILURE
)
